
// export const ENVIROMENT = "DEV";
export const ENVIROMENT = "STAGE";
// export const ENVIROMENT = "LIVE";

// export const BASE_URL = "http://192.168.29.241:1000"; // harita
// export const BASE_URL = "https://ad-anima.com:8081"; //development
export const BASE_URL = "https://ad-anima.com:8080"; //STAGING
// export const BASE_URL = 'https://ad-anima.com:8082'; // live
// export const BASE_URL = 'http://192.168.29.59:1000'; // jignesh

// export const APP_VERSION = "/v1";
// export const APP_VERSION = "/v2";
// export const APP_VERSION = "/v3";
// export const APP_VERSION = "/v4";
// export const APP_VERSION = "/v5";
// export const APP_VERSION = "/v6";
// export const APP_VERSION = "/v7";
export const APP_VERSION = "/v8";

// export const imgUrl = "https://ad-anima.com:8081/public/" // dev
export const imgUrl = "https://ad-anima.com:8080/public/" // stage
// export const imgUrl = "https://ad-anima.com:8082/public/" // live
// export const imgUrl = "http://192.168.29.241:1000/public/" // harita
// export const imgUrl = "http://192.168.29.60:1000/public/" // jignesh
 
export const GOOGLE_API_KEY = "AIzaSyAECw5ncpHgZrKY1Wud8LwefYuOC6Ijnzs"
export const GOOGLE_CLIENT_ID = "464936104690-8amt0i88scjkblidnqbn2u8hekan9n49.apps.googleusercontent.com"
export const GOOGLE_SECRET_ID = "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"

export const URL_PATH = {
    change_mile: '/user/change_radius',
    login: "/user/sign_in",
    user_details: "/user/get_profile",
    guest_user_details: "/user/guest_get_profile",
    user_rating: "/user/rating_review_list",
    guest_user_rating: "/user/guest_rating_review_list",
    service_list: "/user/description_list",
    sign_up: "/user/sign_up",
    edit_service: "/user/edit_service_amount",
    logout: "/user/logout",
    delete_user: "/user/delete_account",
    change_password: "/user/change_password",
    send_otp: "/user/send_otp",
    email_send_otp: "/user/email_send_otp",
    verify_otp: "/user/verify_otp",
    reset_password: "/user/reset_password",
    edit_profile: "/user/edit_profile",
    get_banner_image: "/user/add_provider_banner_image",
    add_services: "/user/add_user_service",
    get_user_service: "/user/user_service_list",
    guest_get_user_service: "/user/guest_user_service_list",
    check_mail: "/user/check_email",
    check_availability: "/booking/booking_availability_check",
    check_availability_guest: "/booking/guest_booking_availability_check",
    check_referral_code: "/user/check_referral_code",
    // home_service_list: '/my_page/home_service_list', // for service in home
    home_service_list: '/my_page/home_service_event_list', // for event listing in home
    // home_guest_service_list: '/my_page/guest_home_service_list', // for service in home -> guest
    home_guest_service_list: '/my_page/guest_home_service_event_list', // for event listing in home -> guset
    save_service: '/my_page/save_unsave_service',
    get_my_page_details: "/my_page/get_my_page",
    add_rating: '/user/add_rating_review',
    add_my_page_details: '/my_page/add_my_page',
    get_my_booking_details: "/booking/my_booking",
    get_my_booking_upcoming_details: "/booking/upcoming_booking_list",
    get_my_booking_past_details: "/booking/past_booking_list",
    get_my_booking_cancel_details: "/booking/cancelled_booking_list",
    get_booking_details: "/booking/get_booking_details",
    upcoming_events_list: "/event/upcoming_event",
    event_notify_user: "/event/event_notify_user",
    upcoming_guest_events_list: "/event/guest_upcoming_event",
    hire_N_collaborate: "/my_page/add_hire_and_collaborate",
    book_schedule_date: "/my_schedule/booked_schedule_dates",
    guest_book_schedule_date: "/my_schedule/guest_booked_schedule_dates",
    my_schedule_date: "/my_schedule/my_scheduled_dates",
    guest_my_schedule_date: "/my_schedule/guest_my_scheduled_dates",
    save_wallet: "/wallet/save_card",
    get_card: '/wallet/card_list',
    delete_card: '/wallet/delete_card',
    payment_refund_request: '/user/payment_refund_request',
    refund_payment: '/user/refund_payment',
    get_bank_info: '/wallet/get_bank_info',
    save_bank_info: '/wallet/save_bank_info',
    add_report: '/wallet/add_report',
    sessionBooking: "/booking/add_booking",
    add_booking_request_session: "/booking/add_booking_request",
    add_booking_request: "/wallet/add_booking_pay",
    modify_booking_request: "/wallet/pay_modify_booking",
    refund_modify_booking_request: "/wallet/refund_modify_booking",
    modified_service_booking: "/booking/modified_service_booking",
    guest_sessionBooking: "/booking/guest_add_booking",
    add_guest_booking_request: "/booking/add_guest_booking_request",
    saveUnSaveEvent: "/my_page/save_unsave_event",
    booking_status: "/booking/booking_status",
    get_system_charge: "/user/get_system_charge",
    delete_Booking_event: "/event/cancel_booking",
    // delete_Booking_event: "/event/cancel_booking",
    schedule_list: "/my_schedule/schedule_list",
    guest_schedule_list: "/my_schedule/guest_schedule_list",
    get_event_detail: "/event/event_detail",
    accept_event_notification: "/event/accept_event_notification",
    cancel_event_notification: "/event/cancel_event_notification",
    accept_recurrent_event_notification: "/event/accept_recurrent_event_notification",
    cancel_recurrent_event_notification: "/event/cancel_recurrent_event_notification",
    get_guest_event_detail: "/event/guest_event_detail",
    check_modify_payment: "/booking/check_modify_payment",
    delete_schedule: "/my_schedule/delete_schedule",
    add_my_schedule: "/my_schedule/add_my_schedule",
    edit_schedule: "/my_schedule/edit_schedule",
    saved_service_list: "/my_page/saved_service_list",
    add_hire_and_collaborate: "/my_page/add_hire_and_collaborate",
    hire_or_collaborate: "/my_page/hire_or_collaborate",
    claim_account: '/claim/claim_account',
    claim_account_without_send_mail: '/claim/claim_account_without_send_mail',
    claim_contact_us: '/claim/claim_contact_us',
    claim_add_message: '/claim/add_message',
    guest_claim_add_message: '/claim/guest_add_message',
    view_profile: '/claim/view_profile',
    view_profile_guest: '/claim/view_profile_guest',
    update_version: '/app_version/update_app_version',
    notification_list: '/user/notification_list',
    notification_view: '/user/notification_view',
    accept_hire_and_collaborate_noti: '/my_page/accept_hire_and_collaborate_noti',
    cancel_hire_and_collaborate_noti: '/my_page/cancel_hire_and_collaborate_noti',
    accept_hire_collab_req: '/user/accept_hire_collab_req',
    cancel_hire_collab_req: '/user/cancel_hire_collab_req',
    modify_hire_collab_req: '/event/modify_hire_collab_req',
    pay_booking: '/wallet/add_booking_pay',
    delete_notifications: '/user/delete_notifications',
    payment_history: '/wallet/wallet_history',
    get_wallet: '/wallet/get_wallet',
    withdraw_amount: '/user/wallet_withdraw',
    event_list: '/user/event_list',
    event_listing: '/user/event_listing',
    event_details: '/user/event_detail',
    event_provider_list: '/user/event_provider_list',
    event_participant_list: '/user/event_participant_list',
    delete_event: '/user/delete_event',
    cancel_event: '/user/cancel_event',
    close_registration: '/user/close_registration',
    upload_package_images: '/user/upload_package_images',
    create_event: '/event/create_event',
    recurrent_noti_detail: '/event/recurrent_noti_detail',
    edit_event: '/event/edit_event',
    add_help_support: '/user/add_help_support',
    user_referral_code_list: '/user/user_referral_code_list',
    all_discount_code: '/discount_code/all_discount_code',
    generate_referral_code: '/user/generate_referral_code',
    get_user_referral_code: '/booking/check_active_referral_code',
    check_discount_code: "/discount_code/check_discount_code",
    discount_provider_event_list: "/discount_code/discount_provider_event_list",
    discount_provider_service_list: "/discount_code/discount_provider_service_list",
    get_discount_code: "/discount_code/get_discount_code",
    create_discount_code: "/discount_code/create_discount_code",
    recurrent_event_detail: "/discount_code/recurrent_event_detail",
    recurrent_package_detail: "/discount_code/recurrent_package_detail",
    edit_discount_code: "/discount_code/edit_discount_code",
    delete_discount_code: "/discount_code/delete_discount_code",
    send_message_pre_populate_event : '/event/send_message_pre_populate_event',
    get_verification_list : '/wallet/generate_onboarding_link',
    verify_discount_code: '/discount_code/verify_discount_code',
    add_question : '/event_survey/add_question',
    edit_question : '/event_survey/edit_question',
    get_question : '/event_survey/get_question',
    add_answer : '/event_survey/add_answer',
    get_answer : '/event_survey/get_answer',
    delete_survey_form : '/event_survey/delete_survey_form'
};
